import { isElementInViewport } from "./utils/ui";
import dialogPolyfill from 'dialog-polyfill';

const EXPIRY_STORAGE_KEY = "AG_EXPIRY";
const DEFAULT_EXPIRATION_SEC = 24 * 60 * 60; // 24 hours
const EXTENDED_EXPIRATION_SEC = 30 * 60 * 60 * 24; // 30 days

const dialog = (): HTMLDialogElement | null =>
  document.querySelector("dialog.js-age-gate");

export const checkValidity = (): boolean => {
  if (
    Number(window.localStorage.getItem(EXPIRY_STORAGE_KEY)) >
    Math.floor(Date.now() / 1000)
  ) {
    return true;
  }

  window.localStorage.removeItem(EXPIRY_STORAGE_KEY);

  return false;
};

const init = (dialog: HTMLDialogElement | null) => {
  if (!(dialog instanceof HTMLElement)) {
    return;
  }

  dialogPolyfill.registerDialog(dialog);

  const enterButton = (): HTMLButtonElement | null =>
    document.querySelector("button.js-age-gate-enter");

  const confirmCheckbox = (): HTMLInputElement =>
    document.querySelector(".js-age-gate-confirm");

  /** Initialize the form events. */
  const initForm = () => {
  	confirmCheckbox()?.addEventListener('change', () => {
  		console.info('checkbox clicked');
  		enterButton()?.toggleAttribute('disabled');
  	});
    enterButton()?.addEventListener("click", () => dialog.close());
  };

  // const hydrateDialog = async (country?: string, subdivision?: string) => {
  //   enterButton()?.setAttribute("disabled", "disabled");

  //   const response = await fetch(
  //     `/api/age-gate?country=${country || ""}&subdivision=${subdivision || ""}`
  //   );

  //   const html = await response.text();

  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(html, "text/html");

  //   if (!(doc.body.firstChild instanceof Element)) {
  //     throw new Error("Could not parse age gate response.");
  //   }

  //   dialog.innerHTML = doc.body.firstChild.innerHTML;

    initForm();
  // };

  if (checkValidity() === false) {
    // hydrateDialog();
    dialog.showModal();
    document.body.style.overflow = "hidden";
  }

  dialog.addEventListener("close", () => {
    const confirmed = confirmCheckbox().checked || false;

    window.localStorage.setItem(
      EXPIRY_STORAGE_KEY,
      (
        Math.floor(Date.now() / 1000) + DEFAULT_EXPIRATION_SEC
      ).toString()
    );
    document.body.style.overflow = "";
  });

  // Do not allow escape key exits for this dialog.
  dialog.addEventListener("cancel", (event) => event.preventDefault());
};

window.addEventListener("load", () => init(dialog()));