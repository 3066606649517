import JsTabs from 'js-tabs'
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import Glide from '@glidejs/glide'
import "./age-gate";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin); 

const dialog = (): HTMLDialogElement | null =>
  document.querySelector("dialog.js-age-gate");

var carousels = [];

// App main
const main = async () => {
    // Async load the Vue 3 APIs we need from the Vue ESM
    // Create our vue instance
    // const app = createApp(App);

    // // Mount the app
    // const root = app.mount('#component-container');

    // return root;

    // Bootstrap smooth scroll
    const scrollers = document.querySelectorAll('a[data-scroller]')
    scrollers.forEach(scroller => {
        scroller.addEventListener('click', (e) => {
            e.preventDefault();
            var target = scroller.getAttribute('href');
            gsap.to(window, {duration: 1, scrollTo: target})
        })
    })

    // Bootstrap scroll behaviour
    // Header animations
    gsap.to('header h1 img', {
        scrollTrigger: {
            trigger: 'header',
            scrub: true,
            start: 'top top',
            end: 'bottom 25%',
        },
        scale: 1.4,
        translateY: '50%',
        opacity: 0
    })
    gsap.to('header a[data-scroller]', {
        scrollTrigger: {
            trigger: 'header',
            scrub: true,
            start: 'top top',
            end: 'bottom 85%',
            // markers: true
        },
        opacity: 0
    })

    // Animated sections
    const animateSections = document.querySelectorAll('section.animate');
    animateSections.forEach(section => {
        gsap.set(section.children, {scale: 0, opacity: 0})
        ScrollTrigger.batch(section.children, {
            onEnter: batch => gsap.to(batch, {scale: 1, opacity: 1, stagger: {each: 0.15}, overwrite: true}),
            // onLeave: batch => gsap.to(batch, {scale: 0, opacity: 0, overwrite: true}),
            // onEnterBack: batch => gsap.to(batch, {scale: 1, opacity: 1, stagger: {each: 0.15}, overwrite: true}),
            // onLeaveBack: batch => gsap.to(batch, {scale: 0, opacity: 0, overwrite: true}),
            once: true
        })
    })

    const myTabs = new JsTabs({
      elm: '#tabs'
    })
    myTabs.init()
};

// Execute async function
main().then( (root) => {
    if (document.readyState !== 'loading') {
        runOnStart();
    } else {
        document.addEventListener('DOMContentLoaded', () => {
            runOnStart();
        })
    }
});

function runOnStart() {
    const glides = document.querySelectorAll('.glide');
    glides.forEach(carousel => {
        const data = carousel.dataset.settings;
        var settings = null;
        try {
            settings = JSON.parse(data);
        } catch (error) {
            settings = {
                type: 'carousel',
                gap: 0,
                focusAt: 'center',
                peek: 0,
                startAt: 0,
            }
        }
        carousels.push({
            elm: carousel,
            glide: new Glide(carousel, settings).mount()
        });
    })
}

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
